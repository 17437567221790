import wretch from 'wretch'
import apiEnvironment from '@platform-shared/utils/apiEnvironment'
import { snakeCaseKeys, camelCaseKeys } from '@platform-shared/utils/caseUtils'
import store from '@platform-shared/store'
import _pickBy from 'lodash/pickBy'
import { isWeb } from '@platform-shared/utils/device'
import clientConfig from '@platform-shared/utils/clientConfig'
import requestCorrelationService from '@platform-shared/utils/requestCorrelationService'

const snakeCaseMiddleware = () => (next) => (url, opts) => {
  if (opts.body !== undefined) {
    return Promise.resolve(
      next(url, {
        ...opts,
        body: JSON.stringify(snakeCaseKeys(JSON.parse(opts.body))),
      })
    )
  }

  return Promise.resolve(next(url, opts))
}

const getBaseUrl = () => {
  if (process.env.VUE_APP_DEVICE === 'web') {
    return window.HM_CONFIG.API_ROOT
  } else {
    const envApiRoot = process.env.VUE_APP_API_ROOT
    const explicitTenantApiRoot = clientConfig.API_ROOT

    if (envApiRoot) {
      return `${envApiRoot}/${clientConfig.ID}`
    } else if (explicitTenantApiRoot) {
      return explicitTenantApiRoot
    } else {
      return apiEnvironment.current().value
    }
  }
}

const getHeaders = () => {
  const memberCoverageInfo = store.getters['member/memberCoverageInfo']
  const dependentsHeader = store.getters['member/dependentsHeader']
  const activeMemberCoverages = store.getters['member/activeMemberCoverages']
  const { memberId } = memberCoverageInfo
  const headers = {}

  if (memberId) {
    headers.HMMemberID = memberId
  }
  if (activeMemberCoverages.length > 0) {
    headers.HMMemberCoverageIDs = activeMemberCoverages
      .map((memberCoverage) => memberCoverage.id)
      .join(',')
  }
  if (dependentsHeader) {
    headers.HMDependents = dependentsHeader
  }

  return headers
}

const customHeaders = () => {
  const headers = {
    'X-Application': `pha/${process.env.VUE_APP_DEVICE}`,
    'X-Correlation-ID': requestCorrelationService.getCorrelationId(),
    'X-Application-Version': process.env.PACKAGE_VERSION,
  }

  if (clientConfig.SCHEMA) {
    headers.HMClientAbbreviation = clientConfig.SCHEMA
    headers.HMS3Partition = clientConfig.S3_PARTITION || clientConfig.SCHEMA
  }

  const preferredLanguageCd = store.getters['member/preferredLanguageCd']
  const preAuthPreferredLanguageCd =
    store.getters['member/preAuthPreferredLanguageCd']
  headers.HMLanguagePref =
    preferredLanguageCd || preAuthPreferredLanguageCd || 'EN'

  headers.HMCohortId = store.getters['member/cohortId']

  return headers
}

const polyfills = isWeb
  ? {
      fetch: require('whatwg-fetch').fetchPolyfill,
    }
  : {}

// this base api function should be used for non authed requests
// Typically you should use api below
export const baseApi = () =>
  wretch()
    .polyfills(polyfills)
    .middlewares([snakeCaseMiddleware()])
    .url(getBaseUrl())
    .headers(_pickBy(customHeaders()))
    .resolve((resolver) => resolver.json((res) => camelCaseKeys(res)))
    .errorType('json')

const api = () =>
  baseApi()
    .catcher(401, () => {
      store.dispatch('forceLogout')
    })
    .headers(getHeaders())
    .auth(`Bearer ${store.getters['member/accessToken']}`)

export default api
