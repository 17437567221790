import { flow, find, get, isEmpty, pick } from 'lodash/fp'
import format from 'date-fns/format'

const getPhoneByType = (type, collection) =>
  flow(find({ type }), get('value'))(collection)

export default {
  isFetching: (state) => state.isFetching,
  firstName: (state, getters, rootState, rootGetters) =>
    state.firstName || rootGetters['member/firstName'],
  lastName: (state) => state.lastName,
  birthday: (state) => state.birthday,
  guardian: (state) => state.guardian,
  guardianBirthday: (state) => state.guardian.dob,
  registrationAutofillPhone: (state) => {
    return {
      number: state.phone1.value,
      type: state.phone1.type,
    }
  },
  registrationInfo: (state) => {
    const cellNumber = getPhoneByType('CELL', [state.phone1, state.phone2])
    const workNumber = getPhoneByType('WORK', [state.phone1, state.phone2])
    const homeNumber = getPhoneByType('HOME', [state.phone1, state.phone2])

    return {
      clientMemberId: state.memberNumber,
      lastName: state.lastName,
      firstName: state.firstName,
      dob: state.birthday && format(state.birthday, 'yyyy-MM-dd'),
      email: state.email,
      password: state.password,
      phone: {
        primary: state.phone1.type,
        cell: cellNumber || undefined,
        work: workNumber || undefined,
        home: homeNumber || undefined,
      },
      address: {
        primary: true,
        streetAddr1: state.street1,
        streetAddr2: state.street2,
        city: state.city,
        state: state.state,
        zip: state.zipCode,
      },
      communicationPreferences: state.communicationPreferences,
      smsOptIn: state.smsOptIn,
      guardian: !isEmpty(state.guardian) ? state.guardian : null,
    }
  },
  registrationSSOInfo: (state, getters) =>
    pick(['email', 'phone', 'address', 'communicationPreferences', 'smsOptIn'])(
      getters.registrationInfo
    ),
  resetPasswordIsFetching: (state) => state.resetPasswordIsFetching,
  changePasswordFromResetIsFetching: (state) =>
    state.changePasswordFromResetIsFetching,
  databasePreferredLanguageCd: (state) =>
    get('databasePreferredLanguageCd', state),
}
