import jwtDecode from 'jwt-decode'
import tokenService from '@platform-shared/utils/tokenService'
import _get from 'lodash/get'

const signInResult = tokenService.getToken()
const accessToken = _get(signInResult, 'accessToken', null)
const impersonationEmail = _get(
  signInResult,
  'impersonatedMember.adminUsername',
  null
)
const impersonatedMemberId = _get(
  signInResult,
  'impersonatedMember.impersonatedMemberId',
  null
)
const decodedAccessToken = accessToken ? jwtDecode(accessToken) : undefined
const tokenExpiration = _get(decodedAccessToken, 'exp', null)
const tokenLoginType = _get(decodedAccessToken, 'hm_login_type', '')

export default () => ({
  accessToken,
  tokenExpiration,
  tokenLoginType,
  id: null,
  username: null,
  firstName: null,
  lastName: null,
  memberCoverages: [],
  isFetching: {
    login: false,
    memberInfo: false,
    memberInfoFromLoginScreen: false,
    update: false,
    agreements: false,
    mfaQrImage: false,
    languagePref: false,
    notificationSettings: false,
    unregister: false,
  },
  impersonationEmail,
  impersonatedMemberId,
  phoneNumbers: { work: '', cell: '', home: '', primary: '' },
  addresses: [],
  email: '',
  gender: null,
  client: { abbreviation: '', name: '', id: '', logo: '' },
  age: null,
  dob: null,
  activity: {},
  disclaimers: [],
  agreements: {
    agreementCatalog: {},
    myAgreements: {},
  },
  dependents: [],
  preferredLanguageCd: '',
  preAuthPreferredLanguageCd: '',
  mfaEnrolled: false,
  mfaType: '',
  mfaQrImage: null,
  memberLoginUrl: '',
  memberPortalUrl: '',
  preferences: [],
  communicationPreferences: [],
  ethnography: {},
})
