import types from './member.types'
import initialState from './member.state'
import _get from 'lodash/get'

const addNewAgreement = (myAgreements, newAgreement) => {
  const { agreementId } = newAgreement

  return {
    ...myAgreements,
    [agreementId]: [newAgreement, ..._get(myAgreements, [agreementId], [])],
  }
}

const updatePrimaryAddress = (addresses, newAddress) => {
  const index = addresses.findIndex((addr) => addr.primary)
  addresses[index] = newAddress
  return [...addresses]
}

export default {
  [types.RESET_STATE]: (state) => {
    Object.assign(state, {
      ...initialState(),
      accessToken: null,
      tokenExpiration: null,
    })
  },

  [types.LOGIN_REQUEST]: (state) => {
    state.isFetching.login = true
  },

  [types.LOGIN_SUCCESS]: (state, { memberId, mfaType }) => {
    state.id = memberId
    state.mfaType = mfaType || ''
    state.isFetching.login = false
  },

  [types.LOGIN_FAILURE]: (state) => {
    state.isFetching.login = false
  },

  [types.MEMBER_INFO_REQUEST]: (state, fromLoginScreen) => {
    state.isFetching.memberInfo = true
    if (fromLoginScreen) {
      state.isFetching.memberInfoFromLoginScreen = true
    }
  },

  [types.MEMBER_INFO_SUCCESS]: (state) => {
    state.isFetching.memberInfo = false
  },

  [types.STORE_TOKEN]: (state, payload) => {
    state.accessToken = payload.jwt
    state.tokenExpiration = payload.exp
    state.tokenLoginType = payload.loginType
  },

  [types.SAVE_MEMBER_INFO]: (state, data) => {
    Object.assign(state, {
      ...data,
      phoneNumbers: Object.assign(state.phoneNumbers, data.phoneNumbers),
    })
  },
  [types.CONTACT_INFO_UPDATE_REQUEST]: (state) => {
    state.isFetching.update = true
  },

  [types.CONTACT_INFO_UPDATE_SUCCESS]: (state, data) => {
    state.phoneNumbers.home = _get(data, 'home', '')
    state.phoneNumbers.work = _get(data, 'work', '')
    state.phoneNumbers.cell = _get(data, 'cell', '')
    if (data.email) {
      state.email = _get(data, 'email', '')
    }
    state.isFetching.update = false
  },

  [types.CONTACT_INFO_UPDATE_FAILURE]: (state) => {
    state.isFetching.update = false
  },
  [types.ADDRESS_UPDATE_REQUEST]: (state) => {
    state.isFetching.update = true
  },

  [types.ADDRESS_UPDATE_SUCCESS]: (state, data) => {
    state.addresses = updatePrimaryAddress(state.addresses, data)
    state.isFetching.update = false
  },

  [types.ADDRESS_UPDATE_FAILURE]: (state) => {
    state.isFetching.update = false
  },

  [types.ETHNOGRAPHY_UPDATE_REQUEST]: (state) => {
    state.isFetching.update = true
  },

  [types.ETHNOGRAPHY_UPDATE_SUCCESS]: (state, data) => {
    state.ethnography = { ...data }
    state.isFetching.update = false
  },

  [types.ETHNOGRAPHY_UPDATE_FAILURE]: (state) => {
    state.isFetching.update = false
  },

  [types.PASSWORD_UPDATE_REQUEST]: (state) => {
    state.isFetching.update = true
  },

  [types.PASSWORD_UPDATE_SUCCESS]: (state) => {
    state.isFetching.update = false
  },

  [types.PASSWORD_UPDATE_FAILURE]: (state) => {
    state.isFetching.update = false
  },

  [types.GET_DISCLAIMERS_SUCCESS]: (state, disclaimers) => {
    state.disclaimers = disclaimers
  },

  [types.INIT_AGREEMENTS_SUCCESS]: (
    state,
    { agreementCatalog, myAgreements }
  ) => {
    state.agreements.agreementCatalog = agreementCatalog
    state.agreements.myAgreements = myAgreements
  },

  [types.CREATE_AGREEMENT_REQUEST]: (state) => {
    state.isFetching.agreements = true
  },

  [types.CREATE_AGREEMENT_SUCCESS]: (state, newAgreement) => {
    state.isFetching.agreements = false
    state.agreements.myAgreements = addNewAgreement(
      state.agreements.myAgreements,
      newAgreement
    )
  },

  [types.CREATE_AGREEMENT_FAILURE]: (state) => {
    state.isFetching.agreements = false
  },

  [types.INIT_ACTIVITY_SUCCESS]: (state, activity) => {
    state.activity = activity
  },

  [types.BEGIN_MFA_ENROLLMENT_REQUEST]: (state, mfaType) => {
    state.isFetching.mfaQrImage = true
    state.mfaType = mfaType
  },
  [types.BEGIN_MFA_ENROLLMENT_SUCCESS]: (state, { url }) => {
    state.isFetching.mfaQrImage = false
    state.mfaQrImage = url
  },
  [types.BEGIN_MFA_ENROLLMENT_FAILURE]: (state) => {
    state.isFetching.mfaQrImage = false
    state.mfaQrImage = null
  },
  [types.RESET_QR_IMAGE]: (state) => {
    state.mfaQrImage = null
  },

  [types.COMPLETE_MFA_ENROLLMENT_SUCCESS]: (state) => {
    state.mfaEnrolled = true
  },

  [types.COMPLETE_MFA_UNENROLLMENT_SUCCESS]: (state) => {
    state.mfaEnrolled = false
  },

  [types.SET_PREFERRED_LANGUAGE_PRE_AUTH]: (state, languageCd) => {
    state.preAuthPreferredLanguageCd = languageCd
  },

  [types.SET_PREFERRED_LANGUAGE]: (state, languageCd) => {
    state.preferredLanguageCd = languageCd
  },
  [types.PREFERRED_LANGUAGE_REQUEST]: (state) => {
    state.isFetching.languagePref = true
  },
  [types.PREFERRED_LANGUAGE_SUCCESS]: (state, languageCd) => {
    state.preferredLanguageCd = languageCd
    state.isFetching.languagePref = false
  },
  [types.PREFERRED_LANGUAGE_FAILURE]: (state) => {
    state.isFetching.languagePref = false
  },

  [types.NOTIFICATION_SETTINGS_REQUEST]: (state) => {
    state.isFetching.notificationSettings = true
  },
  [types.NOTIFICATION_SETTINGS_SUCCESS]: (state, communicationPreferences) => {
    state.communicationPreferences = communicationPreferences
    state.isFetching.notificationSettings = false
  },
  [types.NOTIFICATION_SETTINGS_FAILURE]: (state) => {
    state.isFetching.notificationSettings = false
  },

  [types.UNSUBSCRIBE_REQUEST]: (state) => {
    state.isFetching.notificationSettings = true
  },
  [types.UNSUBSCRIBE_SUCCESS]: (state, communicationPreferences) => {
    state.communicationPreferences = communicationPreferences
    state.isFetching.notificationSettings = false
  },
  [types.UNSUBSCRIBE_FAILURE]: (state) => {
    state.isFetching.notificationSettings = false
  },

  [types.UNREGISTER_MEMBER_REQUEST]: (state) => {
    state.isFetching.unregister = true
  },
  [types.UNREGISTER_MEMBER_SUCCESS]: (state) => {
    state.isFetching.unregister = false
  },
  [types.UNREGISTER_MEMBER_FAILURE]: (state) => {
    state.isFetching.unregister = false
  },
}
