import api, { baseApi } from './api'
import { isMobile } from '../utils/device'

/**
 * Check if the member exists
 * @param {Object} param0 - member info to check
 * @param {string} param0.lastName
 * @param {string} param0.firstName
 * @param {string} param0.birthday
 * @param {string} param0.memberNumber
 */
export const checkEligibility = ({
  lastName,
  firstName,
  birthday: dob,
  memberNumber: clientMemberId,
  guardianBirthday: guardianDOB = '',
}) => {
  const eligibility = `/v1/${process.env.VUE_APP_DEVICE}/member/eligible/`
  /**
   * Note: Wretch supports a `.query({ a: 1, b: 2})` function for query strings,
   * but it's broken on mobile. Doing it this way (string template) for now.
   * Could find a polyfill or write our own if it's worth it down the road.
   */
  const url = `${eligibility}?lastName=${lastName}&firstName=${firstName}&dob=${dob}&clientMemberId=${clientMemberId}&guardianDOB=${guardianDOB}`
  return baseApi()
    .url(url)
    .get()
}

/**
 * Check if the username is valid
 * @param {string} username
 */
export const usernameCheck = (username) => {
  const usernameCheck = `/v1/${process.env.VUE_APP_DEVICE}/member/username-check`
  /**
   * Note: Wretch supports a `.query({ a: 1, b: 2})` function for query strings,
   * but it's broken on mobile. Doing it this way (string template) for now.
   * Could find a polyfill or write our own if it's worth it down the road.
   */
  const url = `${usernameCheck}?username=${encodeURIComponent(username)}`
  return baseApi()
    .url(url)
    .get()
}

export const getUserName = ({
  lastName,
  firstName,
  birthday: dob,
  memberNumber: clientMemberId,
}) => {
  const baseUrl = `/v1/${process.env.VUE_APP_DEVICE}/member/get-username/`
  const url = `${baseUrl}?lastName=${lastName}&firstName=${firstName}&dob=${dob}&clientMemberId=${clientMemberId}`
  return baseApi()
    .url(url)
    .get()
}

/**
 * Login a member
 * @param {Object} creds - the member credentials
 * @param {string} creds.username
 * @param {string} creds.password
 */
export const login = (creds, rememberToken) => {
  const loginUrl = `/v2/${process.env.VUE_APP_DEVICE}/member/login`

  return baseApi()
    .url(loginUrl)
    .post({
      ...creds,
      rememberToken,
      issueRefreshToken: isMobile,
    })
}

/**
 * Login a member with a refresh token
 * @param {string} refreshToken - the stored refresh token
 */
export const loginWithRefreshToken = (refreshToken) => {
  const loginUrl = `/v2/${process.env.VUE_APP_DEVICE}/member/login-with-refresh-token`
  return baseApi()
    .url(loginUrl)
    .post({ refreshToken })
}

/**
 * Renew a members jwt with their refresh token
 * @param {string} refreshToken - the stored refresh token
 */
export const renewJwtFromRefreshToken = (refreshToken) => {
  const renewJwtUrl = `/v1/${process.env.VUE_APP_DEVICE}/member/renew-jwt-with-refresh`
  return api()
    .url(renewJwtUrl)
    .post({ refreshToken })
}

/**
 * Register member
 * @param {Object} registrationInfo - member info used for registration
 */
export const register = (registrationInfo) => {
  const registrationUrl = `/v4/${process.env.VUE_APP_DEVICE}/member/register`
  return baseApi()
    .url(registrationUrl)
    .post({ ...registrationInfo, issueRefreshToken: isMobile })
}

/**
 * Register SSO Member Artificially
 * This does not actually register the member as SSO members are automatically registered on their first sign in by way of our member service.
 * This instead is a means to collect a subset of information that pertains to a (normal) non-SSO registration.
 * @param {Object} registrationSSOInfo - member info used for SSO registration
 */
export const registerSSO = (registrationSSOInfo) => {
  const registrationSSOUrl = `/v1/${process.env.VUE_APP_DEVICE}/member/register-sso`
  return api()
    .url(registrationSSOUrl)
    .post(registrationSSOInfo)
}

export const postRegistrationMemberInfo = () => {
  const postRegistrationMemberInfoUrl = `/v3/${process.env.VUE_APP_DEVICE}/member/post-register-member-info`
  return api()
    .url(postRegistrationMemberInfoUrl)
    .get()
}

export const renewJwt = () => {
  const renewJwtUrl = `/v1/${process.env.VUE_APP_DEVICE}/member/renew-jwt`
  return api()
    .url(renewJwtUrl)
    .get()
}

export const getMemberInfo = (impersonatedMemberId) => {
  const memberInfoUrl = `/v3/${process.env.VUE_APP_DEVICE}/member/member-info${
    impersonatedMemberId ? `?impersonatedMemberId=${impersonatedMemberId}` : ''
  }`
  return api()
    .url(memberInfoUrl)
    .get()
}

/**
 * Reset Password
 * @param {Object} memberInfo - member info used for registration
 */
export const resetPassword = (memberInfo) => {
  const resetPasswordUrl = `/v1/${process.env.VUE_APP_DEVICE}/member/reset-password`
  return api()
    .url(resetPasswordUrl)
    .post(memberInfo)
}

/**
 * Send Reset Password
 * @param {Object} param0 - payload
 * @param {string} param0.username
 * @param {string} param0.oldPassword
 * @param {string} param0.newPassword
 */
export const changePasswordFromReset = ({
  username,
  oldPassword,
  newPassword,
}) => {
  const service = `/v1/${
    process.env.VUE_APP_DEVICE
  }/member/reset-password/${encodeURI(username)}`
  return baseApi()
    .url(service)
    .put({ oldPassword, newPassword })
}
export const updateContactInfo = (contactInfo) => {
  const url = `/v1/${process.env.VUE_APP_DEVICE}/member/update/contact-info`
  return api()
    .url(url)
    .put(contactInfo)
}
export const updateAddress = (address) => {
  const url = `/v1/${process.env.VUE_APP_DEVICE}/member/update/address`
  return api()
    .url(url)
    .put(address)
}
export const updatePassword = ({ oldPassword, newPassword }) => {
  const url = `/v1/${process.env.VUE_APP_DEVICE}/member/update/password/`
  return api()
    .url(url)
    .put({ oldPassword, newPassword })
}

export const updateEthnography = (ethnography) => {
  const url = `/v1/${process.env.VUE_APP_DEVICE}/member/update/ethnography`
  return api()
    .url(url)
    .put(ethnography)
}

/**
 * Get FAQ
 */
export const faq = () =>
  api()
    .url(`/v1/${process.env.VUE_APP_DEVICE}/member/faq`)
    .get()

/**
 * Create Member Agreement
 * @param {string} agreementTag
 */
export const createAgreement = (agreementTag) =>
  api()
    .url(`/v1/${process.env.VUE_APP_DEVICE}/member/agreements/${agreementTag}`)
    .post()

/*
 * Set Member's Preferred Language
 */
export const preferredLanguage = (languageCd) =>
  api()
    .url(`/v1/${process.env.VUE_APP_DEVICE}/member/preferred-language`)
    .put({ languageCd })

/*
 * MFA
 */
export const requestMfaCode = () =>
  api()
    .url(`/v2/${process.env.VUE_APP_DEVICE}/member/mfa/request-code`)
    .post()

export const verifyMfaCode = (totp) =>
  api()
    .url(`/v2/${process.env.VUE_APP_DEVICE}/member/mfa/verify-code`)
    .put({ totp, issueRefreshToken: isMobile })

/*
 * Authentication With Code
 */

export const requestCode = () =>
  api()
    .url(`/v3/${process.env.VUE_APP_DEVICE}/member/authentication-code`)
    .post()

export const checkDeviceCode = (deviceCode) =>
  api()
    .url(
      `/v3/${process.env.VUE_APP_DEVICE}/member/authentication-code/device/${deviceCode}`
    )
    .get()

export const deleteDeviceCode = (deviceCode) =>
  api()
    .url(
      `/v3/${process.env.VUE_APP_DEVICE}/member/authentication-code/device/${deviceCode}`
    )
    .delete()

export const verifyUserCode = (userCode) =>
  api()
    .url(
      `/v3/${process.env.VUE_APP_DEVICE}/member/authentication-code/user-code`
    )
    .post({ userCode })

export const createOrUpdateCommunicationPreferences = (
  communicationPreferences
) => {
  const service = `/v1/${process.env.VUE_APP_DEVICE}/member/communication-preferences`
  return api()
    .url(service)
    .post({ communicationPreferences })
}

export const unsubscribeFromEmailPreferenceWithToken = (
  communicationPreferences,
  token,
  memberId,
  outreachType,
  outreachId
) => {
  const service = `/v1/${process.env.VUE_APP_DEVICE}/member/communication-preferences/memberId/${memberId}/unsubscribe/token/${token}`
  const trackUnsubscribe =
    outreachType && outreachId ? { outreachType, outreachId } : undefined
  return api()
    .url(service)
    .post({
      communicationPreferences,
      trackUnsubscribe,
    })
}

export const unregisterMember = () => {
  const unregisterMemberUrl = `/v1/${process.env.VUE_APP_DEVICE}/member/registration`
  return api()
    .url(unregisterMemberUrl)
    .delete()
}

export const postMemberActivity = ({ activityType, activityPayload = {} }) => {
  const postMemberActivityUrl = `/v4/${process.env.VUE_APP_DEVICE}/member/post-member-activity`

  return api()
    .url(postMemberActivityUrl)
    .post({ activityType, activityPayload })
}
