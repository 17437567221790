import Vue from 'vue'
import Router from 'vue-router'
import get from 'lodash/get'
import store from './store'
import routes from './routes'
import { i18n } from './main'
import heapAnalytics from './utils/heap'
import flow from 'lodash/fp/flow'
import filter from 'lodash/fp/filter'
import map from 'lodash/fp/map'
import head from 'lodash/fp/head'

Vue.use(Router)

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  linkActiveClass: 'is-active',
  routes,
  scrollBehavior: (to, from, savedPosition) => {
    if (savedPosition) {
      return savedPosition
    } else if (to.hash) {
      return {
        selector: to.hash,
      }
    } else {
      return { x: 0, y: 0 }
    }
  },
})

router.beforeEach(async (to, from, next) => {
  const accessToken = store.getters['member/accessToken']
  const isUserAuthenticated = store.getters['member/isAuthenticated']
  const clientConfigHasLoaded = store.getters['client/hasLoaded']
  const hasFeature = store.getters['client/hasFeature']
  const requiresAuth = to.matched.some((record) => record.meta.isAuth)
  const requiresGuest = to.matched.some((record) => record.meta.isGuest)

  if (to.query.lang) {
    i18n.locale = to.query.lang
  }

  if (!clientConfigHasLoaded) {
    await store.dispatch('client/getClientConfig')

    heapAnalytics.init()
  }

  // handle when a token has expired but we haven't removed it yet
  if (accessToken && !isUserAuthenticated) {
    store.dispatch('forceLogout')
    return next('/login')
  }

  // handle routes that require auth
  if (requiresAuth && !isUserAuthenticated) {
    store.dispatch('app/savePostLoginPath', to.fullPath)
    return next({
      name: 'login',
      params: { nextUrl: to.fullPath },
    })
  }

  // grab highest level featureGuard from meta objects on matched routes
  const featureGuard = flow(
    filter((record) => record.meta.featureGuard),
    map(({ meta }) => meta.featureGuard),
    head
  )(to.matched)

  // guard feature specific routes
  if (featureGuard) {
    const { feature, blockingFeature, redirect } = featureGuard

    if (blockingFeature && hasFeature(blockingFeature)) {
      return next(redirect)
    }

    if (feature && !hasFeature(feature)) {
      return next(redirect)
    }
  }

  // handle routes that require guest
  if (requiresGuest && isUserAuthenticated) {
    return next({
      name: from.name || 'home',
    })
  }

  //Handle Title tag
  const nearestWithTitle = to.matched
    .slice()
    .reverse()
    .find((route) => get(route, 'meta.title', null))

  if (nearestWithTitle) document.title = nearestWithTitle.meta.title

  //If we need other meta tags there is a good example found here
  //https://alligator.io/vuejs/vue-router-modify-head/

  //Finally continue
  next()
})

export default router
