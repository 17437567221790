export default {
  RESET_STATE: 'register/reset_state',

  REGISTER_MEMBER_EXISTENCE_REQUEST: 'register/member_existence/request',
  REGISTER_MEMBER_EXISTENCE_SUCCESS: 'register/member_existence/success',
  REGISTER_MEMBER_EXISTENCE_FAILURE: 'register/member_existence/failure',

  REGISTER_USERNAME_VALID_REQUEST: 'register/username_valid/request',
  REGISTER_USERNAME_VALID_SUCCESS: 'register/username_valid/success',
  REGISTER_USERNAME_VALID_FAILURE: 'register/username_valid/failure',

  REGISTER_FAILURE: 'register/failure',
  REGISTER_REQUEST: 'register/request',
  REGISTER_SUCCESS: 'register/success',

  REGISTER_ACCOUNT_INFO: 'register/account_info',
  REGISTER_GUARDIAN_INFO: 'register/guardian_info',
  REGISTER_MEMBER_INFO: 'register/member_info',
  REGISTER_CONTACT: 'register/contact',
  REGISTER_ADDRESS: 'register/address',
  REGISTER_COMMUNICATION_PREFERENCES: 'register/communication_preferences',

  RESET_PASSWORD_REQUEST: 'register/reset_password/request',
  RESET_PASSWORD_SUCCESS: 'register/reset_password/success',
  RESET_PASSWORD_FAILURE: 'register/reset_password/failure',

  CHANGE_PASSWORD_FROM_RESET_REQUEST:
    'register/change_password_from_reset/request',
  CHANGE_PASSWORD_FROM_RESET_SUCCESS:
    'register/change_password_from_reset/success',
  CHANGE_PASSWORD_FROM_RESET_FAILURE:
    'register/change_password_from_reset/failure',

  CHECK_MEMBER_INFORMATION_REQUEST: 'register/check_member_information/request',
  CHECK_MEMBER_INFORMATION_SUCCESS: 'register/check_member_information/success',
  CHECK_MEMBER_INFORMATION_FAILURE: 'register/check_member_information/failure',

  POST_REGISTRATION_MEMBER_INFO_REQUEST:
    'post_registration_member_info/request',
  POST_REGISTRATION_MEMBER_INFO_SUCCESS:
    'post_registration_member_info/success',
  POST_REGISTRATION_MEMBER_INFO_FAILURE:
    'post_registration_member_info/failure',

  SSO_REGISTRATION_INFO_REQUEST: 'sso_registration_info/request',
  SSO_REGISTRATION_INFO_SUCCESS: 'sso_registration_info/success',
  SSO_REGISTRATION_INFO_FAILURE: 'sso_registration_info/failure',
}
