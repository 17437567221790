import _map from 'lodash/map'
import _flatMap from 'lodash/flatMap'
import _get from 'lodash/get'
import _find from 'lodash/find'
import _filter from 'lodash/filter'
import _sumBy from 'lodash/sumBy'
import _sum from 'lodash/sum'
import _size from 'lodash/size'
import _min from 'lodash/min'
import _some from 'lodash/some'
import _head from 'lodash/head'
import _findIndex from 'lodash/findIndex'
import _cloneDeep from 'lodash/cloneDeep'
import fpFlow from 'lodash/fp/flow'
import fpFilter from 'lodash/fp/filter'
import fpGroupBy from 'lodash/fp/groupBy'
import fpMap from 'lodash/fp/map'
import fpOrderBy from 'lodash/fp/orderBy'
import fpGetOr from 'lodash/fp/getOr'
import fpFind from 'lodash/fp/find'
import fpValues from 'lodash/fp/values'
import fpSum from 'lodash/fp/sum'
import fpSumBy from 'lodash/fp/sumBy'
import differenceInMinutes from 'date-fns/differenceInMinutes'
import isToday from 'date-fns/isToday'
import isFuture from 'date-fns/isFuture'
import isBefore from 'date-fns/isBefore'
import startOfDay from 'date-fns/startOfDay'
import parseISO from 'date-fns/parseISO'
import {
  FEATURES,
  SHOULD_LOAD_THRESHOLD,
  CURRENCY_TYPES,
} from '@platform-shared/constants'
import { getCurrentCompliancePeriod } from '../member/member.getters'

const expiredRewardsCheck = (date, truthyValue) =>
  isToday(new Date(date)) || isFuture(new Date(date)) ? truthyValue : 0

export default {
  shouldLoad: (state) => (now = new Date()) => {
    return (
      !state.meta.loadedOn ||
      differenceInMinutes(now, state.meta.loadedOn) > SHOULD_LOAD_THRESHOLD
    )
  },
  hasLoaded: (state) => !!state.meta.loadedOn,
  rewardsInitLoading: (state) => state.meta.isLoading.init,
  rewardsInitError: (state) => state.meta.error.init,
  redeemLoading: (state) => state.meta.isLoading.redeem,
  redeemError: (state) => state.meta.error.redeem,
  incentiveEventRewardsTotal: (state) =>
    fpFlow(
      fpGetOr([], 'incentivesHistory'),
      fpFilter(
        (incentive) =>
          incentive.type === 'REWARD' &&
          !incentive.healthActionCd &&
          isBefore(startOfDay(new Date()), parseISO(incentive.endDate))
      ),
      fpMap((incentive) => incentive.amount || 0),
      fpSum
    )(state),
  availablePointsByHealthActionCompletionId: (state) => (
    healthActionCompletionId
  ) =>
    fpFlow(
      fpFilter(
        (summary) =>
          summary.healthActionCompletionId == healthActionCompletionId
      ),
      fpSumBy(({ availablePoints = 0, expirationDate }) =>
        expiredRewardsCheck(expirationDate, availablePoints)
      )
    )(state.directIncentivePointSummary),
  availablePoints: (state) =>
    _sumBy(state.pointSummary, ({ availablePoints = 0, expirationDate }) =>
      expiredRewardsCheck(expirationDate, availablePoints)
    ),
  totalPoints: (state, getters, rootState, rootGetters) => {
    const memberCoverageInfo = rootGetters['member/memberCoverageInfo']
    const currentPointSummaries = _filter(state.pointSummary, {
      employerIncentiveStrategyId:
        memberCoverageInfo.employerIncentiveStrategyId,
    })

    return _sumBy(currentPointSummaries, 'totalPoints')
  },
  currentRewardsSummary: (state, getters, rootState, rootGetters) => {
    const activeMemberCoverages = rootGetters['member/activeMemberCoverages']
    const maxRewardAmount = _sum(
      _map(activeMemberCoverages, (memberCoverage) => {
        const currentCompliancePeriod = getCurrentCompliancePeriod(
          _get(memberCoverage, 'coverage.compliancePeriods')
        )

        return _get(currentCompliancePeriod, 'maxRewardAmount', 0)
      })
    )

    const maxMemberIncentiveAmount =
      rootGetters['healthActions/totalIncentiveAmountForHealthActions']

    const maxPoints =
      _min([maxRewardAmount, maxMemberIncentiveAmount]) +
      getters.incentiveEventRewardsTotal
    const totalPoints = _min([getters.totalPoints, maxPoints])
    const availableTokens = _sumBy(
      state.tokenSummary,
      ({ availableTokens = 0, expirationDate }) =>
        expiredRewardsCheck(expirationDate, availableTokens)
    )
    return {
      availablePoints: getters.availablePoints,
      totalPoints,
      pointProgress: (totalPoints / maxPoints) * 100,
      availableTokens,
      maxPoints,
      maxedOut: totalPoints >= maxPoints,
    }
  },
  currentYearRedemptionsAmount: (state, getters, rootState, rootGetters) => {
    const memberCoverageInfo = rootGetters['member/memberCoverageInfo']
    const currentRedemptions = _filter(state.incentivesHistory, {
      type: 'REDEEM',
      strategyId: memberCoverageInfo.employerIncentiveStrategyId,
    })
    return -1 * _sumBy(currentRedemptions, 'amount')
  },
  rewardsAvailable: (state) => state.rewardsAvailable,
  rewardBoxesAvailable: (state) => state.rewardBoxesAvailable,
  selectedRewardBox: (state) => state.selectedRewardBox,
  remainingMoney: (state, getters) => (healthActionCompletionId) => {
    if (healthActionCompletionId)
      return getters.directIncentiveRemainingMoney(healthActionCompletionId)
    else return getters.pointsProgramRemainingMoney
  },
  pointsProgramRemainingMoney: (state, getters) => {
    if (state.basket.length > 0) return state.remainingMoney
    return getters.dependentForRedemption
      ? getters.getDependentAvailablePointsById(getters.dependentForRedemption)
      : _get(getters, 'availablePoints', 0)
  },
  directIncentiveRemainingMoney: (state, getters) => (
    healthActionCompletionId
  ) => {
    if (state.basket.length > 0) return state.remainingMoney
    return getters.availablePointsByHealthActionCompletionId(
      healthActionCompletionId
    )
  },
  basket: (state) => state.basket,
  basketGroupedByReward: (state) => {
    return _cloneDeep(state.basket).reduce((acc, item) => {
      const foundItemIdx = _findIndex(acc, { rewardId: item.rewardId })
      if (foundItemIdx === -1) return [...acc, item]
      const existingItem = acc[foundItemIdx]
      acc[foundItemIdx] = {
        ...existingItem,
        quantity: existingItem.quantity + item.quantity,
      }
      return acc
    }, [])
  },
  familyRewardHistory: (state, getters, rootState, rootGetters) => {
    const dependentHistory = _flatMap(
      state.dependents,
      ({ memberId, incentivesHistory }) =>
        _map(incentivesHistory, (historyItem) => ({
          ...historyItem,
          memberName: rootGetters['member/getMemberNameById'](memberId, true),
        }))
    )

    return [...state.incentivesHistory, ...dependentHistory]
  },
  currentFamilyRewardHistory: (state, getters, rootState, rootGetters) => {
    const incentivesHistory = getters.familyRewardHistory
    const incentiveStrategies =
      rootGetters['member/activeEmployerIncentiveStrategies']

    return incentivesHistory.filter((transaction) =>
      incentiveStrategies.includes(transaction.strategyId)
    )
  },
  rewardsHistoryByDate: (state, getters) => {
    const history =
      state.dependents.length > 0
        ? getters.familyRewardHistory
        : state.incentivesHistory
    return fpFlow(
      fpOrderBy(['lastIssuedDate', 'type'], ['desc', 'asc']),
      fpGroupBy('lastIssuedDate')
    )(history)
  },
  currentStrategyRewardsHistory: (state, getters, rootState, rootGetters) => {
    const { employerIncentiveStrategyId } = rootGetters[
      'member/memberCoverageInfo'
    ]
    return _filter(state.incentivesHistory, {
      strategyId: employerIncentiveStrategyId,
    })
  },
  rewardsGroupedByVendorAndValue: (state) => (incentiveCatalogCd) => {
    const rewardsList = incentiveCatalogCd
      ? _get(state.incentiveCatalog, incentiveCatalogCd, [])
      : state.rewardsAvailable

    return fpFlow(
      fpGroupBy((reward) => `${reward.type}${reward.name}${reward.faceValue}`),
      fpMap((reward) => ({
        name: _get(reward, [0, 'name']),
        faceValue: _get(reward, [0, 'faceValue']),
        redemptionAmount: _get(reward, [0, 'redemptionAmount']),
        imageUrl: _get(reward, [0, 'imageUrl']),
        isGiftCard: _get(reward, [0, 'type']) === 'GIFT_CARD',
        isMerchandise: _get(reward, [0, 'type']) === 'MERCHANDISE',
        physical: _find(reward, { deliveryForm: 'physical' }),
        digital: _find(reward, { deliveryForm: 'digital' }),
      })),
      fpOrderBy(['faceValue', 'name'], ['asc', 'asc'])
    )(rewardsList)
  },
  rewardsCarousel: (state, { rewardsGroupedByVendorAndValue }) =>
    fpFlow(
      fpOrderBy([(reward) => reward.name.toLowerCase()], ['asc']),
      fpGroupBy('name'),
      fpMap((vendorData) => {
        const { imageUrl, name } = _head(vendorData)
        return {
          name,
          imageUrl,
          denominations: _map(vendorData, 'faceValue'),
        }
      })
    )(rewardsGroupedByVendorAndValue()),
  groupedAutoRewards: (state) =>
    fpFlow(
      fpGroupBy((reward) => `${reward.type}${reward.name}`),
      fpMap((reward) => ({
        name: _get(reward, [0, 'name']),
        imageUrl: _get(reward, [0, 'imageUrl']),
        isGiftCard: _get(reward, [0, 'type']) === 'GIFT_CARD',
        isMerchandise: _get(reward, [0, 'type']) === 'MERCHANDISE',
        physical: _find(reward, { deliveryForm: 'physical' }),
        digital: _find(reward, { deliveryForm: 'digital' }),
      })),
      fpOrderBy(['name'], ['asc'])
    )(state.rewardsAvailable),
  rewardsWithBasketInfo: (
    state,
    { rewardsGroupedByVendorAndValue, basket = 0 }
  ) => (incentiveCatalogCd) => {
    return rewardsGroupedByVendorAndValue(incentiveCatalogCd).map((reward) => {
      const physicalRewardId = _get(reward, 'physical.id')
      const digitalRewardId = _get(reward, 'digital.id')
      const isInBasket = _some(basket, (item) =>
        [physicalRewardId, digitalRewardId].includes(
          _get(item, 'rewardId', 'x')
        )
      )
      return { ...reward, isInBasket }
    })
  },
  redeemableRewards: (
    state,
    // getters
    {
      rewardsWithBasketInfo,
      availablePoints = 0,
      maxMemberCoverageBalance = 0,
      usesPoints = false,
    },
    rootState,
    rootGetters
  ) => (healthActionId = null) => {
    let rewardsToUse = []

    if (healthActionId) {
      const healthAction = rootGetters['healthActions/healthActionById'](
        healthActionId
      )
      if (healthAction) {
        rewardsToUse = rewardsWithBasketInfo(healthAction.incentiveCatalogCd)
      } else {
        rewardsToUse = []
      }
    } else {
      rewardsToUse = rewardsWithBasketInfo()
    }

    return fpFlow(
      fpFilter((reward) => {
        if (availablePoints === 0) return true
        if (rootGetters['member/activeMemberCoverages'].length === 1)
          return true
        return (
          reward.isInBasket ||
          reward.redemptionAmount <= maxMemberCoverageBalance
        )
      }),
      // TODO: remove the 'x' once the redemption process is the same across points and dollars
      fpGroupBy(
        usesPoints ? (r) => `${r.redemptionAmount}${r.faceValue}` : 'x'
      ),
      fpValues,
      fpOrderBy([(rewardGroup) => _head(rewardGroup).redemptionAmount], ['asc'])
    )(rewardsToUse)
  },
  deliveryAddress: (state) => state.deliveryAddress,
  autoRewardDeliveryAddress: (state) => state.autoRewardDeliveryAddress,
  activeAutoReward: (state) => state.activeAutoReward,
  selectedAutoReward: (state) => state.selectedAutoReward,
  setupAutoRewardLoading: (state) => state.meta.isLoading.setup,
  setupAutoRewardError: (state) => state.meta.error.setup,
  turnOffAutoRewardLoading: (state) => state.meta.isLoading.turnOff,
  autoRewardPreferenceId: (state) => state.preferences.preferenceId,
  confirmAutoReward: (state) => state.confirmAutoReward,
  getDependentAvailablePointsById: (state) => (memberId) => {
    const dependentSummary = fpFlow(
      fpFind({ memberId }),
      fpGetOr([], 'summary')
    )(state.dependents)
    return _sumBy(dependentSummary, ({ availablePoints = 0, expirationDate }) =>
      expiredRewardsCheck(expirationDate, availablePoints)
    )
  },
  familyRewards: (state, getters, rootState, rootGetters) => {
    const dependents = state.dependents

    return [
      {
        name: 'common.you',
        rewardsAvailable: getters.availablePoints,
      },
      ..._map(dependents, ({ memberId }) => ({
        dependentId: memberId,
        name: rootGetters['member/getMemberNameById'](memberId, true),
        rewardsAvailable: getters.getDependentAvailablePointsById(memberId),
      })),
    ]
  },
  showFamilyRewards: (state, getters) => {
    return getters.familyRewards.length > 1
  },
  familyRedeemableCount: (state, getters) => {
    return _size(
      _filter(
        getters.familyRewards,
        ({ rewardsAvailable }) => rewardsAvailable > 0
      )
    )
  },
  dependentForRedemption: (state) => state.dependentForRedemption,
  memberForRedemption: (state, getters) => (healthActionId) => {
    if (healthActionId) {
      return getters.memberForDirectIncentiveRedemption(healthActionId)
    } else {
      return getters.memberForPointsProgramRedemption
    }
  },
  memberForDirectIncentiveRedemption: (state, getters) => (
    healthActionCompletionId
  ) => {
    const availablePoints = getters.availablePointsByHealthActionCompletionId(
      healthActionCompletionId
    )

    return {
      remainingMoney: getters.remainingMoney(healthActionCompletionId),
      availablePoints,
      name: '',
      isDependent: false,
    }
  },
  memberForPointsProgramRedemption: (
    state,
    getters,
    rootState,
    rootGetters
  ) => {
    const isDependent = !!getters.dependentForRedemption
    const availablePoints = isDependent
      ? getters.getDependentAvailablePointsById(getters.dependentForRedemption)
      : _get(getters, 'availablePoints', 0)
    const name = isDependent
      ? rootGetters['member/getMemberNameById'](
          getters.dependentForRedemption,
          true
        )
      : ''
    return {
      remainingMoney: getters.remainingMoney(),
      availablePoints,
      name,
      isDependent,
    }
  },
  usesAutoRewards: (state, getters, rootState, rootGetters) =>
    rootGetters['client/hasFeature'](FEATURES.REWARDS) &&
    rootGetters['client/hasFeature'](FEATURES.AUTO_REWARDS) &&
    !rootGetters['client/hasFeature'](FEATURES.REWARDS_EXTERNAL_REDEMPTION),
  usesAutoRewardsInRegistration: (state, getters, rootState, rootGetters) =>
    getters.usesAutoRewards &&
    rootGetters['client/hasFeature'](FEATURES.AUTO_REWARDS_REGISTRATION),
  usesRewards: (state, getters, rootState, rootGetters) =>
    rootGetters['client/hasFeature'](FEATURES.REWARDS),
  usesRewardRedemption: (state, getters, rootState, rootGetters) =>
    rootGetters['client/hasFeature'](FEATURES.REWARDS) &&
    !rootGetters['client/hasFeature'](FEATURES.REWARDS_EXTERNAL_REDEMPTION),
  currencyType: (state, getters, rootState) => {
    return _get(rootState, 'client.currency')
  },
  usesDollars: (state, getters) =>
    getters.currencyType === CURRENCY_TYPES.DOLLARS,
  usesPoints: (state, getters) =>
    getters.currencyType === CURRENCY_TYPES.POINTS,
  maxMemberCoverageBalance: (state) =>
    state.memberCoverageBalances
      .map(({ availablePoints = 0 }) => availablePoints)
      .reduce(
        (currentMax, availablePoints) =>
          availablePoints > currentMax ? availablePoints : currentMax,
        0
      ),
  canAffordRedemption: (state, { availablePoints = 0, redeemableRewards }) =>
    _min(_map(redeemableRewards().flat(), 'redemptionAmount')) <=
    availablePoints,
  earnedAmountByHealthActionCd: (
    state,
    { currentStrategyRewardsHistory = [] }
  ) => (healthActionCd) =>
    fpFlow(
      fpFilter({ healthActionCd }),
      fpMap('amount'),
      fpSum
    )(currentStrategyRewardsHistory),
  validDirectIncentives: ({ directIncentivePointSummary = [] }) =>
    fpFlow(
      fpFilter(({ expirationDate, availablePoints = 0 }) => {
        const isUnexpired =
          isToday(new Date(expirationDate)) ||
          isFuture(new Date(expirationDate)) ||
          !expirationDate

        return isUnexpired && availablePoints > 0
      })
    )(directIncentivePointSummary),
  directIncentivePointSummaryByHealthActionCompletionId: (state) => (
    healthActionCompletionId
  ) =>
    fpFilter({ healthActionCompletionId }, state.directIncentivePointSummary),
}
